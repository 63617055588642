import './App.css';
import psVideo from './videos/compressed3.mp4'
import beyondTest from './images/Brandon1.webp'
import styled from 'styled-components'
import TopNav from './components/TopNav/TopNav';
import Footer from './components/Footer/Footer'
import { Link } from 'react-router-dom';
import * as Scroll from "react-scroll"
import { Link as Link2, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import team from './images/TheTeam.svg'
import why from './images/TheWhy.svg'
import future from './images/TheFuture.svg'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import mixpanel from 'mixpanel-browser'
import { hotjar } from 'react-hotjar'








const MainContainer = styled.div`
  width: 100%;
  background: whitesmoke;
  padding: 100px;
  box-sizing: border-box;

  h1 {
    font-size: 2.5rem;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media (max-width: 650px) {
    padding: 50px;
    
    h1 {
      font-size: 1.5rem;
    }
  }
`
const MainContainerInner = styled.div`
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
  }

`
const ProjectsContainer = styled.div`
  width: 80%;
  margin: auto;
  height: 38vw;
  display: flex;
  column-gap: 50px;
  margin-top: 100px;

  @media (max-width: 1450px) {
    column-gap: 0px;
    row-gap: 50px;
    flex-direction: column;
    height: 150vw
  }

  @media (max-width: 650px) {
    height: 200vw;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`
const AboutUsParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: auto;
  background: whitesmoke;
  border-radius: 5px;
  margin-top: 50px;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;

  @media (max-width: 650px) {
    width: 100%;
  }
`
const AboutUsContainer = styled.div`
  display: flex;
  margin-top: 50px;
  column-gap: 50px;

  @media (max-width: 1250px) {
    flex-direction: column;
    row-gap: 50px;
    column-gap: 0px;
  }
`
function App() {
  // Update SPA state
  hotjar.stateChange('/');
  mixpanel.track('Sign Up', {
    'source': "Pat's affiliate site",
    'Opted out of email': true,
  });
  ReactGA.send({ hitType: "pageview", page: "/" });
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <>
      <TopNav />
      <MainContainer>
        <div style={{ display: "flex", width: "100%", justifyContent: "center", margin: "auto" }}>
          <MainContainerInner>
            <h1 style={{ fontFamily: "Merriweather", fontWeight: '400', textAlign: "center" }}>Building next-gen art and experiences using advanced algorithms and powerful AI models.</h1>
            <div style={{ margin: "auto", width: "100%", borderTop: "1px solid #cbcbcb", marginTop: "40px" }}></div>
            <p style={{ fontFamily: "Manrope", fontSize: "16px", textAlign: "center", fontWeight: "500", marginTop: "40px" }}>Procedural Labs is a startup currently working on the Procedural Space NFT collection and the next upcoming endeavour 'Beyond'</p>
          </MainContainerInner>
        </div>
      </MainContainer>

      <Element name="projects">
        <h1 className='sectionTitle' style={{ marginTop: "100px" }}>Projects</h1>
        <ProjectsContainer>
          <a href="https://www.proceduralspace.net" style={{ textDecoration: "none", color: "black", flex: "1" }}>
            <div style={{ position: "relative", flex: "1", border: "0px solid #f8f8f8", background: "whitesmoke", height: "100%", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <video style={{ width: "50%" }} src={psVideo} loop muted autoPlay playsInline />
              <div style={{ backdropFilter: "blur(20px)", border: "1px solid #e1e1e1", width: "50px", height: "50px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "0", left: "0", marginLeft: "30px", marginTop: "30px" }}>
                <p style={{ margin: "0", fontFamily: "Merriweather", fontSize: "24px", color: "#e1e1e1" }}>I</p>
              </div>
              <div style={{ backdropFilter: "blur(20px)", padding: "30px", borderTop: "1px solid #e1e1e1", boxSizing: "border-box", marginTop: "auto", bottom: "0", width: "100%", height: "100px", background: "transparent", position: "absolute", display: "flex", alignItems: "center" }}>
                <p style={{ fontFamily: "Merriweather" }}>Procedural Space</p>
                <p style={{ fontFamily: "Manrope", marginLeft: "auto" }}>Website -></p>
              </div>
            </div>
          </a>

          <Link to="/beyond" style={{ flex: "1", overflow: "hidden", position: "relative" }}>
            <div style={{ flex: "1", border: "0px solid black", height: "100%", borderRadius: "5px", position: "relative", overflow: "hidden" }}>
              <img src={beyondTest} alt="beyond" style={{ height: "100%", borderRadius: "5px" }} />
              <div style={{ backdropFilter: "blur(20px)", border: "1px solid #e1e1e1", width: "50px", height: "50px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "0", left: "0", marginLeft: "30px", marginTop: "30px" }}>
                <p style={{ margin: "0", fontFamily: "Merriweather", fontSize: "24px", color: "#e1e1e1" }}>II</p>
              </div>
              <div style={{ backdropFilter: "blur(20px)", padding: "30px", borderTop: "1px solid #e1e1e1", boxSizing: "border-box", marginTop: "auto", bottom: "0", width: "100%", height: "100px", background: "transparent", position: "absolute", display: "flex", alignItems: "center" }}>
                <p style={{ fontFamily: "Merriweather", color: "white" }}>Beyond</p>
                <p style={{ fontFamily: "Manrope", marginLeft: "auto", color: "white" }}>Coming Soon</p>
              </div>
            </div>
          </Link>

        </ProjectsContainer>
      </Element>

      <Element name="about">
        <AboutUsParentContainer>
          <h1 className='sectionTitle' style={{ margin: "0" }}>About Us</h1>
          <AboutUsContainer>
            <div style={{ flex: "1", border: "0px solid black" }}>
              <div style={{ width: "30px", height: "30px", borderRadius: "5px" }}><img src={team} alt="The Team" style={{ width: "100%" }} /></div>
              <h3 style={{ fontFamily: "Merriweather", fontWeight: "400" }}>The Team</h3>
              <p style={{ fontFamily: "Manrope" }}>Procedural Labs consists of a small team of 5 members working hard to create unique digital experiences using procedural technologies.</p>
            </div>
            <div style={{ flex: "1", border: "0px solid black" }}>
              <div style={{ width: "30px", height: "30px", borderRadius: "5px" }}><img src={why} alt="The Team" style={{ width: "100%" }} /></div>
              <h3 style={{ fontFamily: "Merriweather", fontWeight: "400" }}>The Why</h3>
              <p style={{ fontFamily: "Manrope" }}>We have a passion for art and procedural technologies. And we want to showcase this to the world.</p>
            </div>
            <div style={{ flex: "1", border: "0px solid black" }}>
              <div style={{ width: "30px", height: "30px", borderRadius: "5px" }}><img src={future} alt="The Team" style={{ width: "100%" }} /></div>
              <h3 style={{ fontFamily: "Merriweather", fontWeight: "400" }}>The Future</h3>
              <p style={{ fontFamily: "Manrope" }}>Procedural Labs has a ambitious team that is dedicated to creating with time some of the most thought provoking digital art and games experiences in the industry.</p>
            </div>
          </AboutUsContainer>
        </AboutUsParentContainer>
      </Element>

      <Element name="contact">
        <AboutUsParentContainer>
          <h1 className='sectionTitle' style={{ margin: "0" }}>Contact</h1>
          <div style={{ display: "flex", columnGap: '50px', marginTop: "50px", justifyContent: "center" }}>
            <p style={{ fontFamily: "Manrope", padding: "25px", border: "1px solid gray", borderRadius: "5px" }}>admin@procedurallabs.net</p>
          </div>
        </AboutUsParentContainer>


      </Element>
      <Footer />
    </>
  );
}

export default App;
