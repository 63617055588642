import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Beyond from './pages/Beyond';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import mixpanel from 'mixpanel-browser'
import { hotjar } from 'react-hotjar'

ReactGA.initialize("G-6HHZY4L0EL");
const tagManagerArgs = {
  gtmId: 'GTM-K9SSGZ2'
}
TagManager.initialize(tagManagerArgs)
hotjar.initialize(3134914, 6);
// Check if Hotjar has been initialized before calling its methods
if (hotjar.initialized()) {
  hotjar.identify('USER_ID', { userProperty: 'value' });
}
mixpanel.init('d69532531beaaca9ac76b2f7168904cf', { debug: true });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="beyond" element={<Beyond />} />
    </Routes>
  </BrowserRouter>
);

