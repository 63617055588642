import logo from '../../images/PSLogo2Artboard_1.svg';
import '../../App.css';
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const FooterContainer = styled.div`
    font-size: 12px;

    @media (max-width: 650px) {
        font-size: 10px;
    }
`

function Footer() {
    return (
        <>
            <FooterContainer>
                <div style={{ width: "80%", height: "100px", margin: "auto", display: "flex", columnGap: "20px", marginTop: "100px", alignItems: "center" }}>
                    <p style={{ fontFamily: "Manrope", fontWeight: "500" }}>Copyright © 2022 Procedural Labs Pty Ltd</p>
                </div>
            </FooterContainer>
        </>
    );
}

export default Footer;
