import '../App.css';
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer'
import img1 from '../images/Brandon1.webp'
import img2 from '../images/Brandon2.webp'
import img3 from '../images/Element designs_RDomeneghini.webp'
import img4 from '../images/Environment 02_RDomeneghini.webp'
import styled from 'styled-components';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import mixpanel from 'mixpanel-browser'
import { hotjar } from 'react-hotjar'


const ImageContainer = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1250px) {
        width: 100%;
    }
`

const ContentContainer = styled.div`
    width: 50%;

    @media (max-width: 1250px) {
        width: 80%;
    }
`

function Beyond() {
    // Update SPA state
    hotjar.stateChange('/beyond');
    mixpanel.track('Sign Up', {
        'source': "Pat's affiliate site beyond",
        'Opted out of email': true,
    });
    ReactGA.send({ hitType: "pageview", page: "/beyond" });
    window.dataLayer.push({
        event: 'pageview'
    });

    return (
        <>
            <TopNav />
            <h1 style={{ color: 'black', fontFamily: "Merriweather", fontWeight: "400", textAlign: "center" }}>Beyond</h1>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "40px" }}>
                <ContentContainer>
                    <p style={{ textAlign: "center", fontFamily: "Manrope" }}>'Beyond’ is our long-term goal and directional aspiration for our development. ‘Beyond’ is where we combine everything we learned from previous projects into a fully integrated gaming experience. Beyond will not be a metaverse social platform. Instead, it will be a game utilising web3 mechanics with an interactive and deep narrative with many features that bring experiences to the forefront of a player's playstyle.</p>
                </ContentContainer>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "100px" }}>

                <ImageContainer>
                    <img src={img1} alt="Beyond Concept 1" style={{ width: "100%", margin: "auto" }} />
                    <img src={img2} alt="Beyond Concept 2" style={{ width: "100%", margin: "auto" }} />
                    <img src={img3} alt="Beyond Concept 3" style={{ width: "100%", margin: "auto" }} />
                    <img src={img4} alt="Beyond Concept 4" style={{ width: "100%", margin: "auto" }} />
                </ImageContainer>


            </div>
            <Footer />
        </>
    );
}

export default Beyond;
