import logo from '../../images/PSLogo2Artboard_1.svg';
import '../../App.css';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import * as Scroll from "react-scroll"
import { Link as Link2, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useState } from 'react';


const MainNavContainer = styled.div`
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: row;

    @media (max-width: 650px) {
        flex-direction: column;
    }
`

const MenuItem = styled.p`
    font-family: 'Manrope';
    font-size: 14px;
    color: black;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        color: gray;
    }

    @media (max-width: 650px) {
        font-size: 12px;
    }
`

const MobileMenuContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    box-sizing: border-box;
    display: none;

    @media (max-width: 650px) {
        display: block;
    }
`

const StandardMenuContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    
    @media (max-width: 650px) {
        display: none;
    }
`


function MobileMenu() {

    return (
        <>
            <MobileMenuContainer>
                <div style={{ width: "100%" }}>
                    <Link2 to="projects" style={{ borderBottom: "1px solid #c9c9c9" }} >
                        <MenuItem>PROJECTS</MenuItem>
                    </Link2>
                    <Link2 to="about" style={{ borderBottom: "1px solid #c9c9c9" }}>
                        <MenuItem>ABOUT US</MenuItem>
                    </Link2>
                    <Link2 to="contact">
                        <MenuItem>CONTACT</MenuItem>
                    </Link2>
                </div>
            </MobileMenuContainer>
        </>
    )
}

function TopNav() {
    // let Link = Scroll.Link;
    let Button = Scroll.Button;
    let Element = Scroll.Element;
    let Events = Scroll.Events;
    let scroll = Scroll.animateScroll;
    let scrollSpy = Scroll.scrollSpy;

    let path = window.location.pathname;


    return (
        <>
            {/* Navigation */}
            <MainNavContainer>
                <Link to="/">
                    <img style={{ filter: "invert(0)", width: "50px" }} src={logo} alt="logo" />
                </Link>
                <div style={{ display: path === "/beyond" ? "none" : "flex", width: "100%" }}>
                    <MobileMenu></MobileMenu>
                    <StandardMenuContainer>
                        <div style={{ display: "flex", columnGap: "50px", margin: "auto" }}>
                            <Link2 to="projects" >
                                <MenuItem>PROJECTS</MenuItem>
                            </Link2>
                            <Link2 to="about">
                                <MenuItem>ABOUT US</MenuItem>
                            </Link2>
                            <Link2 to="contact">
                                <MenuItem>CONTACT</MenuItem>
                            </Link2>
                        </div>
                    </StandardMenuContainer>
                </div>

            </MainNavContainer>


        </>
    );
}

export default TopNav;
